<template>
  <v-dialog data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Создание</v-card-title>
      <v-card-text>
        <v-row class="row-d-block">
          <v-subheader>Коэффициент</v-subheader>
          <v-text-field
              v-model="koef.koef"
              outlined
              type="number" placeholder="1.1"/>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>На какие типы уборки</v-subheader>
          <v-checkbox
              v-model="koef.standart"
              outlined
              label="Быстрая"
              true-value="1"
              false-value="0"/>
          <v-checkbox
              v-model="koef.general"
              outlined
              label="Генеральная"
              true-value="1"
              false-value="0"/>
          <v-checkbox
              v-model="koef.remont"
              outlined
              label="После ремонта"
              true-value="1"
              false-value="0"/>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Дата</v-subheader>
          <v-row>
            <DatePeriod v-model="koef.period" :intervals="[]"/>
            <v-col>
              <v-select
                  v-model="koef.datetime_fromObj.time"
                  outlined
                  :items="koef_time">
                <template v-slot:prepend>С</template>
              </v-select>
            </v-col>
            <v-col>
              <v-select
                  v-model="koef.datetime_toObj.time"
                  outlined
                  :items="koef_time">
                <template v-slot:prepend>По</template>
              </v-select>
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="addKoef" :loading="loading_save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePeriod from "../../../components/DatePeriod";

export default {
  name: "AddKoef",
  components: {DatePeriod},
  data() {
    let koef_time = []
    for (let i = 3; i < 24; i++) {
      koef_time.push(`${i}:00`)
    }
    let koef = {datetime_fromObj: {time: '3:00'}, datetime_toObj: {time: '23:00'}, period: ['', '']};
    return {
      opened: false,
      initial: JSON.parse(JSON.stringify(koef)),
      koef: JSON.parse(JSON.stringify(koef)),
      loading_save: false,
      menu_from: false,
      koef_time
    }
  },
  methods: {
    open() {
      this.opened = true;
      this.koef = JSON.parse(JSON.stringify(this.initial));
    },
    addKoef() {
      if (!this.koef.period[0]) return this.$root.notify('Укажите период', 'error');
      this.loading_save = true;
      this.koef.datetime_fromObj.date = this.koef.period[0];
      this.koef.datetime_toObj.date = this.koef.period[1] || this.koef.period[0];
      this.$store.state.server.request('koef/create', this.koef, () => {
        this.loading_save = false;
        this.opened = false
        this.$root.notify('Коэффициент добавлен', 'success')
        this.$eventBus.$emit('addedKoef')
      }, (data) => {
        this.loading_save = false;
        this.$root.notify(data.error, 'error')

      })
    }
  }
}
</script>

<style scoped>

</style>
