<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Коэффициенты</v-card-title>
        <v-card-actions>
          <v-btn @click="addKoef">Добавить</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-data-table
              hover
              :items="koefs"
              :headers="koefHeader"
              item-key="id"
              :loading="loading_koefs"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Коэффициентов нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0">
            <template v-slot:item="{item}">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ $root.dateToRus(item.datetime_fromObj.date) }} {{item.datetime_fromObj.date!=item.datetime_toObj.date ? '- '+ $root.dateToRus(item.datetime_toObj.date) : ''}}</td>
                <td>{{ item.datetime_fromObj ? item.datetime_fromObj.time : '' }} -
                  {{ item.datetime_toObj ? item.datetime_toObj.time : '' }}
                </td>
                <td>{{ item.standart === '1' ? 'Да' : 'Нет' }}</td>
                <td>{{ item.general === '1' ? 'Да' : 'Нет' }}</td>
                <td>{{ item.remont === '1' ? 'Да' : 'Нет' }}</td>
                <td>{{ item.koef }}</td>
                <td>
                  <v-btn class="v-btn-danger" @click="deleteKoef(item.id)">Удалить</v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <AddKoef ref="addKoef"/>
  </v-app>
</template>

<script>
import AddKoef from "@/views/other/koefs/AddKoef";

export default {
  name: "Koefs",
  components: {
    AddKoef,
  },
  data() {

    return {
      menu_date_koef: false,
      koef_filters: {
        date: null,
      },
      koefHeader: [
        {text: '#', value: 'id'},
        {text: 'Дата', value: 'date'},
        {text: 'Время', value: 'time'},
        {text: 'Быстрая', value: 'standart'},
        {text: 'Генеральная', value: 'general'},
        {text: 'После ремонта', value: 'remont'},
        {text: 'Коэффициент', value: 'koef'},
        {text: '', value: 'delete'},
      ],
      koefs: [],
      loading_koefs: true,
    }
  },
  methods: {
    getKoefs() {
      this.$store.state.server.request('koef/get', {}, (data) => {
        this.loading_koefs = false;
        this.koefs = data.response
      }, (data) => {
        this.loading_koefs = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },
    addKoef() {
      const modal = this.$refs.addKoef;
      modal.open()
    },
    deleteKoef(koef_id) {
      if (!confirm('Удалить коэффициент?')) return false;
      this.$store.state.server.request(`koef/delete/${koef_id}`, {}, () => {
        this.$root.notify('Коэффициент удален', 'success')
        this.getKoefs()
      }, (data) => {
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    }
  },
  mounted() {
    this.getKoefs()
  },
  created() {
    this.$eventBus.$on('addedKoef', () => {
      this.getKoefs()
    });
  },
  destroyed() {
    this.$eventBus.$off('addedKoef');
  }
}
</script>

<style scoped>

</style>
